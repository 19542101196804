import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getSessionById } from "../api/services/api";

export const Timer = (sessionUrl: any, startTime?: any) => {
  const [getEndDateTime, setGetEndDateTime] = useState<any>();
  const [difference, setDifference] = useState<number>(0);

  const { data, isLoading } = useQuery(
    ["sessionDetail", sessionUrl?.sessionUrl],
    getSessionById,
    {
      enabled: !!sessionUrl?.sessionUrl,
    }
  );

  useEffect(() => {
    if (data?.data?.date && data?.data?.endAt) {
      const endTime = new Date(
        `${data.data.date} ${data.data.endAt}`
      ).getTime();
      setGetEndDateTime(endTime);
    }
  }, [data]);

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = new Date().getTime();
      if (getEndDateTime) {
        const timeDifference = getEndDateTime - currentTime;
        setDifference(Math.max(0, timeDifference));
      } else {
        const elapsedTime = currentTime - startTime;
        setDifference(elapsedTime);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [getEndDateTime, startTime]);

  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return (
    <Box
      mt={2}
      maxWidth="95px"
      borderRadius="10px"
      bgcolor={"#D58E4E"}
      px={3}
      py={1}
      display="flex"
    >
      <Typography>{minutes.toString().padStart(2, "0")}</Typography>
      <Typography>:</Typography>
      <Typography>{seconds.toString().padStart(2, "0")}</Typography>
    </Box>
  );
};
